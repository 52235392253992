.service_item {
  position: relative;
  overflow: hidden;
  height: 500px;
}

.service_item::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -80px;
  right: -80px;
  border: 80px solid white;
  transform: rotate(45deg);
  opacity: 1;
  transition: 0.5s;
}

.bg_white::after {
  border-color: transparent transparent #f2f8fe transparent;
}

.bg_light::after {
  border-color: transparent transparent #ffffff transparent;
}

.service_item:hover::after {
  opacity: 0;
}

.font_ {
  font-family: "Ubuntu", sans-serif;
  color: #2b4a99;
  font-size: 75px;
}

.second_font {
  font-family: "Hind Siliguri", sans-serif;
  font-size: 18px;
  text-align: left;
}

.btn_5 {
    border: 0 solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
    outline: 1px solid;
    outline-color: rgba(255, 255, 255, .5);
    outline-offset: 0px;
    text-shadow: none;
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    color: #134bf2;
  } 
  
  .btn_5:hover {
    border: 1px solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, .5), 0 0 20px rgba(255, 255, 255, .2);
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 10px;
    text-shadow: 1px 1px 2px #1291c7; 
  }
