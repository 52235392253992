@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');


.divise{
    margin-top: 3%;
}

.grid{

   display: grid;
   width: 540px;
   grid-template-columns: 500px 500px 500px;
   align-items: start;
   justify-content: space-between;

}

.gauche{
	width:40%;
	float:left;
	background-color: white;
	margin:0;
}

.droite{
    float:right;
	background-color:white;
	width:40%;
	margin-left:-10%;
}

.font{
    font-family: 'Ubuntu', sans-serif;
    font-size: 50px;
    color: #ABD473;
}
.font_{
    font-family: 'Ubuntu', sans-serif;
    font-size: 50px;
    color: #2B4A99;
}
.font_com{
  font-family: 'Ubuntu', sans-serif;
  
}
.second_font{
    color: #2B4A99;
    font-family: 'Hind Siliguri', sans-serif;
    font-size: 30px;
}
.font2{
    font-family: 'Archivo Narrow', sans-serif;
}

.font_p{
  font-family: 'Cabin', sans-serif;
}

.btn{
  width: 250px;
  height: 50px;
  cursor: pointer;
  background: #2B4A99;
  border: 1px solid #91C9FF;
  color: white;
  outline: none;
  transition: 1s ease-in-out;
}
.btn:hover {
  transition: 1s ease-in-out;
  background: #4F95DA;
}

.animated_quote{
display:inline-block;
  margin:1em;
  max-width:20em;
  position:relative;
  background-color: whitesmoke;
  height: 80%;
  }
 
.cite {
      display: block;
      font-style: italic;
      text-align: right;
    }
    .blockquote {
    animation:shadows 2s linear infinite alternate;
    display:inline-block;
    height: 100%;
    margin:0;
    padding:1em;
  }
    @keyframes shadows {
        0% {
          box-shadow:0 2px 4px -2px rgba(0,0,0,.25);
          transform:scale(.95);
        }
        100% {
          box-shadow:0 0 4px 2px rgba(0,0,0,.25);
          transform:scale(1);
        }
      }

      .features_area .single_features {
        margin-bottom: 0; }
      .features_inner {
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        padding: 40px 0; }
      
      .single_features {
        text-align: center;
        border-right: 1px solid #eeeeee; }
        @media (max-width: 991px) {
          .single_features {
            border-right: none;
            margin-bottom: 30px; } }
        
        .single_features h6 {
          font-size: 16px;
          margin-bottom: 0; }
        .single_features p {
          margin-bottom: 0; }
        .single_features:hover  {
          opacity: .5; }


          .tab{
            display: none;
        }
        
        .tab:target {
            display: block;
        }
        
        .top_right {
            position: absolute;
            top: 25%;
            right: 30%;
          }
        
          .container {
            position: relative;
            text-align: center;
            color: white;
          }
          .top_left {
            position: absolute;
            top: 8%;
            right: 38%;
          }
          .testimonial_carousel::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 100%;
            width: 0;
            background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            z-index: 1;
        }
        
        .testimonial_carousel::after {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            height: 100%;
            width: 0;
            background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            z-index: 1;
        }