.divise{
    margin-top: 3%;
}

.font{
    font-family: 'Ubuntu', sans-serif;
    font-size: 50px;
    color: #ABD473;
}
.font_{
    font-family: 'Ubuntu', sans-serif;
    font-size: 50px;
    color: #2B4A99;
}
.second_font{
    color: #2B4A99;
    font-family: 'Hind Siliguri', sans-serif;
    font-size: 30px;
}
.font2{
    font-family: 'Archivo Narrow', sans-serif;
}
.font_p{
    font-family: 'Cabin', sans-serif;
}