.grad {
  background: #314755; /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #314755, #26a0da); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #314755, #26a0da);
    padding: 2%;
    color: antiquewhite;
  }

  .anima{
    animation:shadows 2s linear infinite alternate;
    display:inline-block;
    margin:0;
    padding:1em;}
    @keyframes shadows {
        0% {
          
          transform:scale(.95); 
        }
        100% {
         
          transform:scale(1);
        }
  }

  .font2{
    font-family: 'Archivo Narrow', sans-serif;
    font-size: larger;
    color: #2B4A99;
}

.font_{
  font-family: 'Ubuntu', sans-serif;
  font-size: 50px;
  color: #2B4A99;
}
.font_p{
  font-family: 'Cabin', sans-serif;
}