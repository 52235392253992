@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');

.divise{
    margin-top: 6%;
}

.font{
    font-family: 'Ubuntu', sans-serif;
    font-size: 50px;
    color: #ABD473;
}
.font_{
    font-family: 'Ubuntu', sans-serif;
    font-size: 50px;
    color: #2B4A99;
}
.second_font{
    color: #ABD473;
    font-family: 'Hind Siliguri', sans-serif;
    font-size: 30px;
}

.id:hover{
    background-color: aliceblue;
}