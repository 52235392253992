.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: rgb(64, 14, 246);
  }

  @media screen {
    .centered {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 30px;
      color: rgb(64, 14, 246);
    }
  }

  .font_p{
    font-family: 'Cabin', sans-serif;
  }