.service_item1 {
    position: relative;
    z-index: 1;
    padding: 30px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
    background-color: #ABD473;
    color: #fff;
    border-radius: 10px;
    border-top-right-radius: 50px;
    transition: all .3s;
    height: 350px;
  }
  .service_item1:hover{
    position: relative;
    z-index: 1;
    color: #fff;
    padding: 30px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
    background-color: #2B4A99;
    border-radius: 10px;
    border-top-right-radius: 50px;
    transition: all .3s;
  }
  
  
  
  .service_item_h4 {
    transition: all .3s;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  
  .service_item_p {
    transition: all .3s;
    font-size: 15px;
    margin-bottom: 20px;
  }

  .font {
    font-family: 'Ubuntu', sans-serif;
    font-size: 30px;
    color: #ABD473;
  }

  .font_{
    font-family: 'Ubuntu', sans-serif;
    color: #2B4A99;
}

  