.main_footer {
    padding: 70px 0;
    display: flex;
    justify-content: space-evenly;
    background-color: #19302E;
  }
  
  .main_footer_ul {
    list-style: none;
  }
  
  .main_footer_h1 {
    font-size: 22px;
    line-height: 117%;
    color: #ffffff;
    margin-bottom: 10px;
    font-weight: 500;
  }
  
  .main_footer_h2 {
    color: #ffffff;
    font-weight: 500;
  }
  
  .main_footer_ul_li_a {
    color: #ffffffcc;
    text-decoration:none;
  }
  
  footer {
    background-color: #000000;
    border-top: 1px solid #413ef5;
    font-size: 17px;
    padding: 15px 5px;
    color: #ffffff;
    text-align: center;
  }
  
  footer a {
    text-decoration: none;
    color: #ffffff;
  }
  
  .logoinfo_p {
    color: #6EB981;
    font-size: 17px;
    margin-top: 5px;
  }
  
  .contact_details {
    margin-top: 20px;
  }
  
  .contact_details_li {
    list-style: none;
    margin: 10px 0;
  }
  
  .contact_details_li_a {
    text-decoration:none;
    color: #f1f1f1;
  }
  
  .contact-details .fa {
    color: #f1f1f1;
    margin-right: 10px;
  }
  
  .sociallogos{
    padding:20px 0;
  }
  
  .logobox_a{
    padding:0 10px;
    text-decoration:none;
    color:#ffffff;
    font-size:22px;
  }
  
  .com ul li{
    padding:5px 0;
  }

  .f{
color: #213556;
  }