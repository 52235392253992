
/* another ,,,,,,,,,,,,,,,,,,,,,, */
/* another jhrfhefjhjlgjhgjhrghjhrgjhrgj */


/* .container_{
  width: 1000px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.container_ .card{
  position: relative;
  cursor: pointer;
}

.container_ .card_ .face_{
  width: 300px;
  height: 200px;
  transition: 0.5s;
}

.container_ .card_ .face_ .face1_{
  position: relative;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
}

.container_ .card_:hover .face_ .face1_{
  background: #ff0057;
  transform: translateY(0);
}

.container_ .card_ .face_ .face1_ .content_{
  opacity: 0.2;
  transition: 0.5s;
}

.container_ .card_:hover .face_ .face1_ .content_{
  opacity: 1;
}

.container_ .card_ .face_ .face1_ .content_ .img_{
  max-width: 100px;
}

.container_ .card_ .face_ .face1_ .content_ .h3_{
  margin: 10px 0 0;
  padding: 0;
  color: #fff;
  text-align: center;
  font-size: 1.5em;
}

.container_ .card_ .face_ .face2_{
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  transform: translateY(-100px);
}

.card_:hover .face_ .face2_{
  transform: translateY(0);
}

.p_{
  margin: 0;
  padding: 0;
}

.a_{
  margin: 15px 0 0;
  display:  inline-block;
  text-decoration: none;
  font-weight: 900;
  color: #333;
  padding: 5px;
  border: 1px solid #333;
}

.a_:hover{
  background: #333;
  color: #fff;
} */


/* .card_ {
  width: 190px;
  height: 120px;
  transition: all .5s;
  box-shadow: 15px 15px 30px rgba(25, 25, 25, 0.11),
             -15px -15px 30px rgba(60, 60, 60, 0.082);
  text-align: center;
  overflow: hidden;
}

.card_:hover {
  height: 254px;
  background: linear-gradient(360deg, #edededc5 60%, hsla(0, 0%, 13%, 1) 70%);
}

.card_ .header_ {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #212121;
  margin-bottom: 16px;
}

.card_ .header_ .img_box {
  width: 50px;
}

.card_ .header_ .title_ {
  font-size: 1.1em;
  letter-spacing: .1em;
  font-weight: 500;
  text-transform: uppercase;
  padding: 4px 0 14px 0;
  transition: all .5s;
  color: #edededc5;
}

.card_:hover .header_ {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 96%);
}

.card_:hover .card_ .header_ .title_ {
  padding: 0;
}

.card_ .content_ {
  display: block;
  text-align: justify;
  color: #212121;
  margin: 0 18px;
}

.card_ .content_ .p_ {
  transition: all .5s;
  font-size: 1em;
  margin-bottom: 8px;
}

.card_ .content_ .a_ {
  color: #1d8122;
  cursor: pointer;
  transition: all .5s;
  font-size: .8rem;
  font-weight: 500;
  text-transform: uppercase;
}

.card_ .content_ .btn_link:hover {
  border-bottom: 1px solid #1d8122;
} */

.container_{
  display: flex;
  justify-content: center;
  padding: 80px;
}

.square:hover {
    -webkit-transform: translate(20px, -10px);
    -ms-transform: translate(10px, -10px);
    transform: translate(10px, -10px);
    -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
     }


.square{
  width: 460px;
  height: 430px;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 20px 50px #D9DBDF;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; 
}

.mask{
  clip: rect(0px, 460px, 220px, 0px);
  border-radius: 4px;
  position: absolute;
}



.h1{
  margin: auto;
  text-align: left;
  margin-top: 240px;
  padding-left: 30px;
  
  font-family: 'Merriweather', serif;
  font-size: 24px;
}
.p_{
 text-align: justify; 
 padding-left: 30px;
 padding-right: 30px;
 font-family: 'Open Sans', sans-serif;
 font-size: 12px;
 color: #C8C8C8;
 line-height: 18px;
}

.button_ {
    background-color: #3EDD84;
    color: white;
    width: 90px;
    padding: 10px 18px;
    border-radius: 3px;
    text-align: center;
    text-decoration: none;
    display: block;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 70px;
    font-size: 12px;
    cursor: pointer;
    font-family: 'merriweather'; }