.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

  .slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 500px;
  }
  
  .slide {
    display: inline-block;
  
    height: 400px;
    width: 100%;
    border-radius: 40px;
  }

  .card {
    
    max-width: 500px;
    margin: auto;
    text-align: left;
    font-family: arial;
    float: left;
    margin-left: 2%; 
  }

  .card_chifr{
    max-width: 500px;
    margin: auto;
    text-align: left;
    font-family: arial;
    float: center;
    margin-left: 2%; 
  }

  .container{
    
    margin-top: 10%;
    
  }

  