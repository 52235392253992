/* .tab{
    display: none;
}

.tab:target {
    display: block;
}

.top_right {
    position: absolute;
    top: 25%;
    right: 30%;
  }

  .top_right1 {
    position: absolute;
    top: 62%;
    right: 30%;
  }

  .container {
    position: relative;
    text-align: center;
    color: white;
  } */

.tab {
  display: none;
  margin-top: 10%;
}

.tab:target {
  display: block;
}

.top_right {
  position: absolute;
  top: 43%;
  right: 53%;
}

.top_right1 {
  position: absolute;
  top: 73%;
  right: 54%;
}

.top_right2 {
  position: absolute;
  top: 35%;
  right: 57%;
}

.top_right3 {
  position: absolute;
  top: 30%;
  right: 61%;
}
.top_right4 {
  position: absolute;
  top: 34%;
  right: 68%;
}
.container {
  position: relative;
  text-align: center;
  color: white;
}


.animated_quote {
  display: inline-block;
  margin: 1em;
  max-width: 20em;
  position: relative;
  background-color: whitesmoke;
  height: 80%;
}

.cite {
  display: block;
  font-style: italic;
  text-align: right;
}
.blockquote {
  animation: shadows 2s linear infinite alternate;
  display: inline-block;
  height: 100%;
  margin: 0;
  padding: 1em;
}
@keyframes shadows {
  0% {
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
    transform: scale(0.95);
  }
  100% {
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.25);
    transform: scale(1);
  }
}

.features_area .single_features {
  margin-bottom: 0;
}
.features_inner {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 40px 0;
}

.single_features {
  text-align: center;
  border-right: 1px solid #eeeeee;
}
@media (max-width: 991px) {
  .single_features {
    border-right: none;
    margin-bottom: 30px;
  }
}

.single_features h6 {
  font-size: 16px;
  margin-bottom: 0;
}
.single_features p {
  margin-bottom: 0;
}
.single_features:hover {
  opacity: 0.5;
}


/*test flex */



