.font_{
    font-family: 'Ubuntu', sans-serif;
    color: #2B4A99;
    font-size: 50px;
}
.font{
    font-family: 'Ubuntu', sans-serif;
    font-size: 50px;
    color: #ABD473;
}
.second_font {
    color: #abd473;
    font-family: "Hind Siliguri", sans-serif;
    font-size: 20px;
  }
  
  .font_p{
    font-family: 'Cabin', sans-serif;
  }
.divise{
    margin-top: 3%;
}
.team_item {
    position: relative;
    padding: 30px;
    text-align: center;
    transition: .5s;
    z-index: 1;
        
}


.team_item::before,
.team_item::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 45%;
    top: 0;
    left: 0;
    border-radius: 5px;
    background: #FFFFFF;
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
    transition: .5s;
    z-index: -1;
}

.team_item::after {
    top: auto;
    bottom: 0;
}

.team_item:hover::before,
.team_item:hover::after {
    background: #abd473;
}

.team_item_h5,
.team_item_p {
    transition: .5s;
}

.team_item:hover h5,
.team_item:hover p {
    color: #FFFFFF;
}

.team_item_img {
    padding: 10px;
    border: 1px solid #abd473;
    width: 100%;
    height: 100%;
}

.nav_link {
    margin-right: 35px;
    padding: 20px 0;
    color: var(--dark);
    font-size: 18px;
    font-weight: 500;
    outline: none;
}

.nav_link:hover,
.nav_link.active {
    color: var(--primary);
}

.dropdown_toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}