.divise {
  margin-top: 4%;
}

.font {
  font-family: "Ubuntu", sans-serif;
  font-size: 50px;
  color: #abd473;
}
.font1 {
  font-family: "Ubuntu", sans-serif;
  font-size: 50px;
  color: #2b4a99;
}
.font2 {
  font-family: "Ubuntu", sans-serif;
  font-size: 40px;
  color: #2b4a99;
}
.font_ {
  font-family: "Ubuntu", sans-serif;
  font-size: 30px;
  color: #2b4a99;
}
.fonts {
  font-family: "Ubuntu", sans-serif;
  font-size: 40px;
}
.fonts_ {
  font-family: "Ubuntu", sans-serif;
  font-size: 10px;
}
.fonts__ {
  font-family: "Ubuntu", sans-serif;
  
}
.second_font {
  color: #abd473;
  font-family: "Hind Siliguri", sans-serif;
  font-size: 25px;
}

.font_p{
  font-family: 'Cabin', sans-serif;
}

.text_over_img {
  z-index: 1;
  font-size: 1.2em;
  margin-top: -100px;
}

.features_area {
  margin-bottom: 0;
  margin-top: 5%;
  
}
.features_inner {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 40px 0;
  
}

.single_features {
  text-align: center;
  border-right: 1px solid #eeeeee;
  height: 200px;  
}
@media (max-width: 991px) {
  .single_features {
    border-right: none;
    margin-bottom: 30px;
  }
}

.single_features h6 {
  font-size: 16px;
  margin-bottom: 0;
}
.single_features p {
  margin-bottom: 0;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo_sites{
  width: 10%;
  float: right;
}

@media screen and (min-width: 80rem) {
  .logo_sites {
    width: 15%;
  }
}

.testimonials {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 20px;
  margin: 200px auto 100px;
}
.testimonials_card {
  position: relative;
  width: 350px;
  margin: 0 auto;
  background: #abd473;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}


.testimonials_card_layer {
  z-index: 2.5;
  position: absolute;
  width: 100%;
  height: 100%;
  top: calc(100% - 300px);
  background: #03a9f4;
  left: 0;
  background: linear-gradient(#abd473, #2b4a99);
  transition: 0.5s;
}
.testimonials_card_layer:hover{
  top: 0%;
}
.testimonials_card_content {
  z-index: 2;
  position: relative;
}
.testimonials_content_p {
  font-size: 15px;
  line-height: 24px;
  color: #fff;
}
.testimonials_image {
  max-width: 200px;
  max-height: 200px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  object-fit: contain;
  border: 4px solid #e2dede;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  background-color: white;
  
}
.testimonials .card .content img {
  max-width: 100px;
  max-height: 100px;
}
.testimonials_details_h2 {
  color: #fff;
  font-size: 20px;
}
.testimonials_details_h2:hover {
  color: #fff;
  font-size: 20px;
}
.testimonials_span {
  color: #03a9f4;
  font-size: 14px;
  transition: 0.5s;
}
.testimonials_span:hover {
  color: #fff;
}