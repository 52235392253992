.font {
  font-family: 'Ubuntu', sans-serif;
  font-size: 30px;
  color: #ABD473;
}

.team_item {
    position: relative;
    padding: 30px;
    text-align: center;
    transition: .5s;
    z-index: 1;
    width: 100%;
        
}

.team_item::before,
.team_item::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 40%;
    top: 0;
    left: 0;
    border-radius: 5px;
    background: #FFFFFF;
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
    transition: .5s;
    z-index: -1;
}

.team_item::after {
    top: auto;
    bottom: 0;
}

.team_item:hover::before,
.team_item:hover::after {
    background: #abd473;
}

.team_item_h5,
.team_item_p {
    transition: .5s;
}

.team_item:hover h5,
.team_item:hover p {
    color: #FFFFFF;
}

.team_item_img {
    padding: 10px;
    border: 1px solid #abd473;
    width: 100%;
    height: 100%;
}

.font_{
    font-family: 'Ubuntu', sans-serif;
    color: #2B4A99;
}

.ul{
    counter-reset: li; 
    list-style: none; 
    padding: 0;
    text-shadow: 0 1px 0 rgba(255,255,255,.5);
  }
  
  .li{
    position: relative;
    display: block;
    padding: .4em .4em .4em 2em;
    margin: .5em 0;
    background: #DAD2CA;
    color: #444;
    text-decoration: none;
    border-radius: .3em;
    transition: .3s ease-out;
  }
  
  .li:hover {
    background: #DCDDE1;
  } 
  
  .li:hover:before { 
    transform: rotate(360deg); }
  
  .li:before {
    /* content: counter(li);
    counter-increment: li; */
    position: absolute;
    left: -1.3em;
    top: 50%;
    margin-top: -1.3em;
    background: #f9dd94;
    height: 3em;
    width: 2em;
    line-height: 2em;
    border: .3em solid #fff;
    text-align: center;
    font-weight: bold;
    border-radius: 2em;
    transition: all .3s ease-out;
  }